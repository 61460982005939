import { useQuery } from '@apollo/client';
import { useCookies } from '@fergdigitalcommerce/fergy-react-components';
import React, { type FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { configureRoute } from '../../../utils/routes/configure-route';
import { type HomePageQuery, type HomePageQueryVariables } from '../../__generated__/graphql-client-types';
import { PageLoading } from '../../components/common-components/loading/loading.component';
import { PageContainer } from '../../components/common-components/page/page-container/page-container.component';
import { FiveHundred } from '../../components/error-components/500/500.component';
import { HomeCarousel } from '../../components/home-components/home-carousel/home-carousel.component';
import { HomeSectionFactory } from '../../components/home-components/home-section-factory/home-section-factory.component';
import { HomeSplash } from '../../components/home-components/home-splash/home-splash.component';
import { HomeDashboardContainer } from '../../components/home-dashboard/home-dashboard-container.component';
import { DEFAULT_META_DESCRIPTION, FEATURE_FLAGS } from '../../constants/general';
import { HOMEPAGE_LINK } from '../../constants/links';
import { useTrackPageView } from '../../hooks/analytics/analytics.hooks';
import { useDyRecommendationContext, DyRecommendationContextType } from '../../hooks/dynamic-yield/dynamic-yield-client.hooks';
import { useFeature } from '../../hooks/features/features.hooks';
import { HOMEPAGE } from '../../queries/homepage/homepage.queries';
import { visuallyHidden } from './home.css';

export const Home: FunctionComponent = () => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const contentItemId = params.get('contentitemid');
	const { data, error, loading } = useQuery<HomePageQuery, HomePageQueryVariables>(HOMEPAGE, {
		fetchPolicy: 'cache-first',
		variables: { input: { ...(contentItemId ? { contentItemId } : {}) } }
	});
	const isHomeDashboardActive = useFeature(FEATURE_FLAGS.HOMEPAGE_DASHBOARD);
	const { getCookie } = useCookies();
	const isHomePageDashboardCookieSetToFalse = getCookie('ab-homepage-dashboard') === 'false';
	const homepageContent = data?.homepage;

	useTrackPageView({ pageName: 'home' }, { page: 'home' }, loading);
	useDyRecommendationContext({ type: DyRecommendationContextType.HOME_PAGE });

	if (loading) {
		return <PageLoading />;
	} else if (error) {
		return <FiveHundred />;
	}
	return (
		<div className="bg-theme-white">
			{homepageContent && (
				<PageContainer
					canonicalURL={HOMEPAGE_LINK}
					pageTitle="Bathroom, Kitchen - Faucets, Sinks, Hardware - Build with Ferguson"
					metaDescription={DEFAULT_META_DESCRIPTION}
					layout="minimized"
					showUpdateLocation={true}>
					<>
						{(isHomeDashboardActive || isHomePageDashboardCookieSetToFalse) && (
							<HomeDashboardContainer isHomePageDashboardCookieSetToFalse={isHomePageDashboardCookieSetToFalse} />
						)}
						<HomeSplash {...homepageContent.splash} />
						<HomeCarousel
							items={homepageContent.saleCategories.items}
							automationHook="carousel-top-category-swipe"
							navigationVerticalPosition={-40}
						/>
						<ul className="list ma0 pa0 flex flex-column justify-between items-center w-100" data-testid="sections">
							{homepageContent?.sections.map((section, index) => (
								<li className="flex flex-column w-100 pa0 mh0 mt6 mb0" key={index}>
									<HomeSectionFactory {...section} />
								</li>
							))}
						</ul>
					</>
				</PageContainer>
			)}
			<h1 className={`overflow-hidden absolute bn pa0 ${visuallyHidden}`}>
				Find Bathroom and Kitchen Sinks, Faucets and Hardware on Build.com
			</h1>
		</div>
	);
};

export const RoutedHomePage = configureRoute(
	{ path: HOMEPAGE_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, ddRum: { rumViewName: 'Home' } },
	Home
);
