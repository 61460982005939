import React, { type FunctionComponent } from 'react';
import {
	DEFAULT_HOME_CAROUSEL_SMALL_CARD_MIN_WIDTH,
	HOME_CAROUSEL_SMALL_OPTIONS,
	DEFAULT_HOME_CAROUSEL_LARGE_CARD_MIN_WIDTH,
	HOME_CAROUSEL_LARGE_OPTIONS,
	DEFAULT_HOME_CAROUSEL_MEDIUM_CARD_MIN_WIDTH,
	HOME_CAROUSEL_MEDIUM_OPTIONS,
	EXCLUSIVE_DEALS_CAROUSEL_SMALL_CARD_MIN_WIDTH,
	EXCLUSIVE_DEALS_CAROUSEL_MEDIUM_CARD_MIN_WIDTH,
	EXCLUSIVE_DEALS_CAROUSEL_LARGE_CARD_MIN_WIDTH
} from '../../../constants/home/general';
import { type HomePageCarouselItem } from '../../../helpers/home/home-carousel/home-carousel.helpers';
import { Carousel } from '../../carousel/carousel.component';
import { isExclusiveDealsItem } from '../home-common.helpers';
import { type HomeExclusiveDealsCardOptionProps } from '../home-exclusive-deals-card-option/home-exclusive-deals-card-option.component';
import { HomeCarouselOptionCard, type HomeCarouselOptionCardProps } from './home-carousel-option-card/home-carousel-option-card.component';

export type HomeCarouselProps = {
	items: HomePageCarouselItem[];
	CardComponent?: FunctionComponent<HomeCarouselOptionCardProps | HomeExclusiveDealsCardOptionProps>;
	title?: string;
	automationHook?: string;
	navigationVerticalPosition?: number;
	cardMinWidth?: number;
	sectionClassName?: string;
	rootMargin?: string;
};

export const HomeCarousel: FunctionComponent<HomeCarouselProps> = ({
	items,
	title,
	CardComponent = HomeCarouselOptionCard,
	automationHook,
	navigationVerticalPosition,
	cardMinWidth,
	sectionClassName = 'list ma0 pa0 flex flex-column justify-between w-100',
	rootMargin
}) => {
	// check if the card component is an exclusive deals item
	const firstItem = items.length > 0 ? items[0] : null;
	const isExclusiveItem = firstItem ? isExclusiveDealsItem(firstItem) : false;

	const defaultCardWidths = {
		smallCard: isExclusiveItem ? EXCLUSIVE_DEALS_CAROUSEL_SMALL_CARD_MIN_WIDTH : DEFAULT_HOME_CAROUSEL_SMALL_CARD_MIN_WIDTH,
		mediumCard: isExclusiveItem ? EXCLUSIVE_DEALS_CAROUSEL_MEDIUM_CARD_MIN_WIDTH : DEFAULT_HOME_CAROUSEL_MEDIUM_CARD_MIN_WIDTH,
		largeCard: isExclusiveItem ? EXCLUSIVE_DEALS_CAROUSEL_LARGE_CARD_MIN_WIDTH : DEFAULT_HOME_CAROUSEL_LARGE_CARD_MIN_WIDTH
	};

	const { smallCard, mediumCard, largeCard } = defaultCardWidths;

	return items.length > 0 ? (
		<section
			className={`${sectionClassName} ${isExclusiveItem && items.length <= 3 ? 'items-center-ns' : ''}`}
			data-automation={automationHook}>
			{title && (
				<div className="flex flex-column items-center w-100">
					<h3 className="fw2 f2-ns f3 mb3 mt0">{title}</h3>
				</div>
			)}

			{/* mobile */}
			<div className="db dn-ns">
				<Carousel
					shouldLockNavigation={isExclusiveItem && items.length <= 3 ? false : true}
					navigationVerticalPosition={navigationVerticalPosition}
					// converting from rem to px, plus 8 accounting for padding between the cards
					cardWidth={16 * (cardMinWidth ?? smallCard) + 8}>
					{items.map((item, index) => (
						<div
							key={item.title}
							data-testid={`${item.title}-${index}`}
							className="mh1 mh2-ns"
							style={{
								minWidth: `${cardMinWidth ?? smallCard}rem`
							}}>
							<CardComponent item={item} itemsCount={items.length} cloudinaryOptions={HOME_CAROUSEL_SMALL_OPTIONS} />
						</div>
					))}
				</Carousel>
			</div>

			{/* tablet/desktop */}
			<div className="dn db-ns">
				<Carousel
					rootMargin={rootMargin}
					shouldLockNavigation={isExclusiveItem && items.length <= 3 ? false : true}
					navigationVerticalPosition={navigationVerticalPosition}>
					{items.map((item) => (
						<React.Fragment key={item.title}>
							{/* desktop */}
							<div
								className="dn db-l mh1 mh2-ns"
								style={{
									minWidth: `${cardMinWidth ?? largeCard}rem`
								}}>
								<CardComponent item={item} itemsCount={items.length} cloudinaryOptions={HOME_CAROUSEL_LARGE_OPTIONS} />
							</div>
							{/* tablet */}
							<div
								className="dn db-m mh1 mh2-ns"
								style={{
									minWidth: `${cardMinWidth ?? mediumCard}rem`
								}}>
								<CardComponent item={item} itemsCount={items.length} cloudinaryOptions={HOME_CAROUSEL_MEDIUM_OPTIONS} />
							</div>
						</React.Fragment>
					))}
				</Carousel>
			</div>
		</section>
	) : null;
};
