import React, { type FunctionComponent } from 'react';
import { type ImageFieldsFragment } from '../../../__generated__/graphql-client-types';
import { PLACEHOLDER_OPTIONS } from '../../../constants/cloudinary/general';
import { EXCLUSIVE_DEALS_TEXT_STYLES } from '../../../constants/home/general';
import { findMaxCardWidth } from '../../../helpers/home/home-carousel/home-carousel.helpers';
import { type ExclusiveDealsItem } from '../../../types/home.types';
import { Link } from '../../common-components/link/link.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import {
	CarouselCardOptionWithSubtitle,
	HomeCarouselOptionCard
} from '../home-carousel/home-carousel-option-card/home-carousel-option-card.component';
import { findExclusiveDealsArtworkImageCloudinaryOptions } from '../home-common.helpers';

export type ResponsiveArtworkImageProps = {
	isNotSmall: boolean;
	responsiveClassName?: string;
	artworkImage: ImageFieldsFragment;
	itemsCount: number;
	className?: string;
};

const ResponsiveArtworkImage: FunctionComponent<ResponsiveArtworkImageProps> = ({
	isNotSmall,
	responsiveClassName,
	artworkImage,
	itemsCount,
	className
}) => (
	<CloudinaryImage
		{...artworkImage}
		className={`${className} ${responsiveClassName}`}
		options={findExclusiveDealsArtworkImageCloudinaryOptions(itemsCount, isNotSmall)}
		useDimensions={false}
		publicID={artworkImage.id}
		placeholderOptions={{
			...findExclusiveDealsArtworkImageCloudinaryOptions(itemsCount, isNotSmall),
			...PLACEHOLDER_OPTIONS
		}}
	/>
);

export const ArtworkImage = ({ artworkImage, itemsCount, link, className = 'br2 br--top w-100' }) => {
	const { name, url, tracking } = link;

	return (
		<Link url={url} analyticsHook={tracking} ariaLabel={name} className="flex">
			<ResponsiveArtworkImage
				artworkImage={artworkImage}
				itemsCount={itemsCount}
				className={className}
				isNotSmall={false}
				responsiveClassName="db dn-ns"
			/>
			<ResponsiveArtworkImage
				artworkImage={artworkImage}
				itemsCount={itemsCount}
				className={className}
				isNotSmall={true}
				responsiveClassName="dn db-ns"
			/>
		</Link>
	);
};
export type HomeExclusiveDealsCardOptionProps = {
	item: ExclusiveDealsItem;
	itemsCount: number;
};
export const HomeExclusiveDealsCardOption: FunctionComponent<HomeExclusiveDealsCardOptionProps> = ({ item, itemsCount }) => {
	const { artworkImage, trackingLink } = item;
	const { name, url, tracking } = trackingLink;
	const isSingleDisplay = itemsCount === 1;
	return (
		<div className={`w-100 flex flex-column items-center`} style={{ maxWidth: findMaxCardWidth(itemsCount), minWidth: 'auto' }}>
			<div className="relative flex flex-column items-center justify-between w-100">
				{isSingleDisplay ? (
					<div className="db dn-ns w-100">
						<ArtworkImage artworkImage={artworkImage} itemsCount={itemsCount} link={trackingLink} />
					</div>
				) : (
					<ArtworkImage artworkImage={artworkImage} itemsCount={itemsCount} link={trackingLink} />
				)}
				<HomeCarouselOptionCard
					item={item}
					imageClassName={`br2 ${isSingleDisplay ? 'br--top-ns' : 'br--bottom'}`}
					isSingleDisplay={isSingleDisplay}
				/>
				{isSingleDisplay ? (
					<div className="db dn-ns">
						<Link url={url} analyticsHook={tracking} ariaLabel={name} className="flex">
							<CarouselCardOptionWithSubtitle
								title={item.title}
								subtitle={item.subtitle}
								containerClassName={`flex flex-column items-center justify-between pa2 w-100 theme-${
									EXCLUSIVE_DEALS_TEXT_STYLES[isSingleDisplay ? 'BLACK' : item.textStyle]
								}`}
								titleClassName={'f4 f3-ns b lh-title'}
								subtitleClassName={'mt0 mt2-l'}
							/>
						</Link>
					</div>
				) : (
					<Link url={url} analyticsHook={tracking} ariaLabel={name} className="flex">
						<CarouselCardOptionWithSubtitle
							title={item.title}
							subtitle={item.subtitle}
							containerClassName={`flex flex-column items-center justify-between pa2 w-100 theme-${
								EXCLUSIVE_DEALS_TEXT_STYLES[isSingleDisplay ? 'BLACK' : item.textStyle]
							}`}
							titleClassName={'f4 f3-ns b lh-title'}
							subtitleClassName={'mt0 mt2-l'}
						/>
					</Link>
				)}
				{isSingleDisplay && (
					<div className="dn db-ns">
						<Link url={url} analyticsHook={tracking} ariaLabel={name} className="flex">
							<CarouselCardOptionWithSubtitle
								title={item.title}
								subtitle={item.subtitle}
								containerClassName={`absolute z-1 bottom-0 right-0 flex flex-column items-center pv4 pv5-l mb2-l ph0 w-50 theme-${
									EXCLUSIVE_DEALS_TEXT_STYLES[item.textStyle]
								}`}
								titleClassName="f4 f3-ns b lh-title"
								subtitleClassName="mt0 mt2-l"
							/>
						</Link>
						<ArtworkImage
							artworkImage={artworkImage}
							itemsCount={itemsCount}
							link={trackingLink}
							className="flex flex-column items-center justify-between w-100 br2 br--bottom"
						/>
					</div>
				)}
			</div>
		</div>
	);
};
