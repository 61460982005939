import loadable from '@loadable/component';
import React, { type FunctionComponent } from 'react';
import { useSiteViewPreference } from '../../hooks/apollo/employee/employee.hooks';
import type { InternalPriceTooltipContentProps } from './internal-price-tooltip-content.component';

const LoadableInternalPriceTooltip = loadable(
	() => import(/* webpackChunkName: "internal-price-tooltip" */ './internal-price-tooltip-content.component'),
	{
		resolveComponent: ({ InternalPriceTooltipContent }) => InternalPriceTooltipContent
	}
);

export type InternalPriceTooltipProps = InternalPriceTooltipContentProps;

/**
 * Simple wrapper around InternalPriceTooltipContent that only loads it if the user is internal
 */
export const InternalPriceTooltip: FunctionComponent<InternalPriceTooltipProps> = (args) => {
	const { showAsEmployee } = useSiteViewPreference();
	return showAsEmployee ? <LoadableInternalPriceTooltip {...args} /> : null;
};
